<template>
    <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
    import echarts from 'echarts'
    import resize from './mixins/resize'

    require('echarts/theme/macarons') // echarts theme
    export default {
        mixins: [resize],
        props: {
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '300px'
            },
            autoResize: {
                type: Boolean,
                default: true
            },
            chartData: {
                type: Object,
                required: true
            },
            startColor: {
                type: String,
                default: ''
            },
            endColor: {
                type: String,
                default: ''
            }
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                    this.setOptions(val)
                }
            }
        },
        data() {
            return {
                chart: null
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                this.chart = echarts.init(this.$el, 'macarons')
                this.setOptions(this.chartData)
            },
            setOptions(chartData) {
                let options = {
                    color: '#607EFA', // 柱状图颜色
                    backgroundColor: '', // 背景颜色透明
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        // top: 10,
                        // left: '2%',
                        // right: '2%',
                        left: 20,
                        right: 20,
                        top: 20,
                        bottom: 20,
                        containLabel: true
                    },
                    // dataZoom: [{
                    //     type: 'inside',
                    //     textStyle: {
                    //         color: '#fff',
                    //     },
                    // }, {
                    //     type: 'slider',
                    //     textStyle: {
                    //         color: '#fff',
                    //     },
                    //     // backgroundColor: 'green', // 背景色
                    //     // fillerColor: 'red', // 选中范围的填充颜色。
                    // }
                    // ],
                    xAxis: [{
                        type: 'category',
                        data: chartData.names,
                        axisTick: {
                            show: false,
                            alignWithLabel: true
                        },
                        axisLabel: { // 轴文字标签
                            show: true,
                            textStyle: {
                                color: '#666666',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        },
                        axisLine: {
                            show: false
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        axisLabel: { // 轴文字标签
                            show: true,
                            textStyle: {
                                color: '#666666',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        },
                        // nameTextStyle: {
                        //     color: '#fff'
                        // },
                        // splitLine: {
                        //     show: true,
                        //     lineStyle: {
                        //         color: ['#113D89'],
                        //         width: 1,
                        //         type: 'dotted' // dashed dotted
                        //     }
                        // },
                        splitArea: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                    }],
                    series: [{
                        // name: '报名数',
                        type: 'bar',
                        stack: 'vistors',
                        // barWidth: '60%',
                        barMaxWidth: '60',
                        data: chartData.values,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#333333',
                            fontSize: 16,
                            fontWeight: 400
                        },
                        animationDuration: 2800
                    }]
                };
                if (this.startColor && this.endColor) {
                    options.series[0].itemStyle = {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: this.startColor },
                            { offset: 1, color: this.endColor }
                        ])
                    }
                }
                this.chart.setOption(options)
            }
        }
    }
</script>
