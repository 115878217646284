<template>
    <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
    import echarts from 'echarts'
    import resize from './mixins/resize'
    require('echarts/theme/macarons') // echarts theme

    export default {
        mixins: [resize],
        props: {
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '300px'
            },
            autoResize: {
                type: Boolean,
                default: true
            },
            chartData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                chart: null
            }
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                    this.$nextTick(() => {
                        this.setOptions(val)
                    })
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                this.chart = echarts.init(this.$el, 'macarons')
                this.setOptions(this.chartData)
            },
            setOptions(data) {
                this.chart.setOption(
                    {
                        backgroundColor: '', // 背景颜色透明
                        grid: {
                            left: 10,
                            right: 10,
                            bottom: 20,
                            top: 30,
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            // axisPointer: {
                            //     type: 'cross'
                            // },
                            padding: [5, 10]
                        },
                        xAxis: {
                            type: 'category',
                            data: data.names,
                            axisLabel: { // 轴文字标签
                                show: true,
                                textStyle: {
                                    color: '#666666',
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            // 网格线不显示
                            splitLine: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: { // 轴文字标签
                                show: true,
                                textStyle: {
                                    color: '#666666',
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            // nameTextStyle: {
                            //     color: '#fff'
                            // },
                            // splitLine: {
                            //     show: true,
                            //     lineStyle: {
                            //         color: ['#113D89'],
                            //         width: 1,
                            //         type: 'dotted' // dashed dotted
                            //     }
                            // },
                            splitArea: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                        },
                        series: [{
                            // name: '报名球队数量',
                            data: data.values,
                            type: 'line',
                            smooth: true,
                            symbolSize: 8,
                            label: {
                                show: true,
                                position: 'top',
                                color: '#333333',
                                fontSize: 16,
                                fontWeight: 400
                            },
                            itemStyle: {
                                normal: {
                                    color: '#1E90FF',
                                    lineStyle: {
                                        color: '#1E90FF',
                                        width: 2
                                    },
                                    areaStyle: {
                                        // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: 'rgba(30, 144, 255, 0.31)'
                                        },
                                            {
                                            offset: 1,
                                            color: 'rgba(30, 65, 255, 0)'
                                        }]),
                                    }
                                }
                            },
                        }]
                    }
                );
            },
            setOptions2(data) {
                this.chart.setOption({
                    xAxis: {
                        data: data.names,
                        boundaryGap: false,
                        axisTick: {
                            show: false
                        }
                    },
                    grid: {
                        left: 10,
                        right: 10,
                        bottom: 20,
                        top: 20,
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        padding: [5, 10]
                    },
                    yAxis: {
                        axisTick: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: 'actual',
                            smooth: true,
                            type: 'line',
                            itemStyle: {
                                normal: {
                                    color: '#3888fa',
                                    lineStyle: {
                                        color: '#3888fa',
                                        width: 2
                                    },
                                    areaStyle: {
                                        color: '#f3f8ff'
                                    }
                                }
                            },
                            data: data.values,
                            animationDuration: 2800,
                            animationEasing: 'quadraticOut'
                        }]
                })
            }
        }
    }
</script>
