<template>
    <div class="num-card">
        <div class="left">
            <div class="top">{{count}}{{unit}}</div>
            <div class="line"></div>
            <div class="desc">{{title}}</div>
        </div>
        <div class="right">
            <img class="img" :src="imgUrl">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0
        },
        unit: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        imgUrl: null
    }
}
</script>

<style lang="scss" scoped>
    .num-card {
        flex: 1;
        padding: 30px 30px 30px 40px;
        height: 120px;
        background: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 20px;
        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .top {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 24px;
                color: #1E90FF;
                opacity: 1;
            }
            .line {
                width: 100px;
                height: 2px;
                background: linear-gradient(90deg, rgba(30, 144, 255, 0.5) 0%, rgba(30, 144, 255, 0) 100%);
                opacity: 1;
                border-radius: 0px;
            }
            .desc {
                line-height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 0px;
                color: #333333;
                opacity: 1;
            }
        }
        .right {
            text-align: right;
            .img {
                width: 50px;
                height: auto;
            }
        }
    }
    .num-card:last-child {
        margin-right: 0 !important;
    }
</style>
