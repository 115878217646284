<template>
    <div class="report-main">
        <div class="header">
            <span class="line"></span>
            <h1 class="title">数据统计</h1>
        </div>
        <div class="row-common">
            <NumberCard :title="'注册用户'" :count="totalData.userCount" :unit="'人'"
                        :imgUrl="require('../../assets/images/reportv2/pcount@2x.png')"></NumberCard>
            <NumberCard :title="'机构用户'" :count="totalData.adminCount" :unit="'人'"
                        :imgUrl="require('../../assets/images/reportv2/newsc.png')"></NumberCard>
            <NumberCard :title="'考试报名'" :count="totalData.baomingCount" :unit="'人'"
                        :imgUrl="require('../../assets/images/reportv2/fsaic.png')"></NumberCard>
            <NumberCard :title="'课程总数'" :count="totalData.courseCount" :unit="'个'"
                        :imgUrl="require('../../assets/images/reportv2/saishi.png')"></NumberCard>
            <NumberCard :title="'试题库'" :count="totalData.examCount" :unit="'题'"
                        :imgUrl="require('../../assets/images/reportv2/team.png')"></NumberCard>
            <NumberCard :title="'视频总数'" :count="totalData.videoCount" :unit="'个'"
                        :imgUrl="require('../../assets/images/reportv2/team.png')"></NumberCard>
        </div>

        <div class="row-common">
            <div class="card">
                <div class="head">
                    <div>
                        <span class="title">视频日学习时长（分钟）</span>
                    </div>
                </div>
                <div class="body">
                    <line-chart :chart-data="dayChartData"  :height="'400px'"/>
                </div>
            </div>
        </div>
        <div class="row-common">
            <div class="card" style="width: 50% !important;">
                <div class="head">
                    <div>
                        <span class="title">月课程购买情况</span>
                    </div>
                </div>
                <div class="body">
                    <BarChart :chart-data="buyChartData" :height="'450px'" :startColor="'#607EFA'" :endColor="'#1E90FF'"></BarChart>
                </div>
            </div>
            <div class="card" style="width: 50% !important;">
                <div class="head">
                    <div>
                        <span class="title">月报名情况</span>
                    </div>
                </div>
                <div class="body">
                    <BarChart :chart-data="enrollChartData" :height="'450px'" :startColor="'#607EFA'" :endColor="'#1E90FF'"></BarChart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NumberCard from "@v/dashboard/components/NumberCard";
// import RingChart from "@v/dashboard/components/RingChart";
import BarChart from "@v/dashboard/components/BarChart";
import LineChart from "@v/dashboard/components/LineChart";
// import RankTable from "@v/dashboard/components/RankTable";
export default {
    components: {
        NumberCard,
        // RingChart,
        BarChart,
        LineChart,
        // RankTable
    },
    data() {
        return {
            totalData: {
                userCount: 0,
                adminCount: 0,
                baomingCount: 0,
                courseCount: 0,
                examCount: 0,
                videoCount: 0,
            },
            dayChartData: {},
            gnvqChartData: [
                {
                    "name": "已完成",
                    "value": 8
                },
                {
                    "name": "进行中",
                    "value": 4
                },
                {
                    "name": "未开始",
                    "value": 3
                }
            ],
            buyChartData: {
                names: ["1", "2", "3", "4", "5", "6"],
                values: [654, 654, 4400, 5300, 6200]
            },
            enrollChartData: {
                names: ["职业资格", "技能等级", "特种作业", "特种设备", "测试工种01", "测试工种02"],
                values: [654, 654, 4400, 5300, 6200]
            }
        }
    },
    mounted() {
        this.getData();
        this.getChartData();
    },
    methods: {
        getData: function () {
            let data = {
                isAdmin: true,
                userId: this.appCookies.getCookieUID(),
                orgId: this.appCookies.getCookieOID()
            }
            this.apis.get('report/getTotalCount', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.totalData = res.data || {};
                        this.$forceUpdate();
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getChartData () {
            this.apis.get('/report/monthStudyTimes?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        let result = res.data || [];

                        let dayChartData = {
                            names: [],
                            values: []
                        }
                        result.forEach(item => {
                            dayChartData.names.push(item.studyDay)
                            dayChartData.values.push(item.studyTime)
                        })
                        this.dayChartData = dayChartData
                    } else {
                        this.dayChartData = {}
                    }
                }).catch(err => {
                console.log(err);
            });
            this.apis.get('/report/getMonthBuy?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        let result = res.data || [];

                        let dayChartData = {
                            names: [],
                            values: []
                        }
                        result.forEach(item => {
                            dayChartData.names.push(item.studyDay)
                            dayChartData.values.push(item.studyTime)
                        })
                        this.buyChartData = dayChartData
                    } else {
                        this.buyChartData = {}
                    }
                }).catch(err => {
                console.log(err);
            });
            this.apis.get('/report/getMonthEnroll?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        let result = res.data || [];

                        let dayChartData = {
                            names: [],
                            values: []
                        }
                        result.forEach(item => {
                            dayChartData.names.push(item.studyDay)
                            dayChartData.values.push(item.studyTime)
                        })
                        this.enrollChartData = dayChartData
                    } else {
                        this.enrollChartData = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        changeMonth: function () {
            this.queryMap();
            this.getCountList();
            this.getRegionEnrollList();
            this.getRegionTeamList();
            this.getMonthEnrollList();
            this.getSchedRankList();
        },
        showRank: function () {
            this.$router.push({ path: '/report/rank', query: { year: this.queryParams.year, provinceId: this.queryParams.provinceId, cityId: this.queryParams.cityId, districtId: this.queryParams.districtId }});
        },
        allChina: function () {
            this.queryParams.provinceName = '';
            this.queryParams.regionPy = '';
            this.queryParams.mapLevel = 'china';
            this.queryMap();
        },
        drillMap: function (regionType, regionName, regionPy) {
            console.log(regionType, regionName, regionPy)

            this.queryParams.regionPy = regionPy;
            this.queryParams.provinceName = regionName;
            this.queryParams.mapLevel = regionType;
            // this.queryMap();
            // console.log(this.$route)
            this.$store.dispatch('tagsView/delView', this.$route);
            this.$router.push({ path: '/report/province', query: { year: this.queryParams.year, provinceName: regionName, regionPy: regionPy, mapLevel: regionType }});
        },
        queryMap: function () {
            this.apis.postForm('/system/report/geteMapList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.mapData = res.data || [];
                        this.$refs.echartMap.refreashMap(this.mapData, this.queryParams.regionPy, this.queryParams.mapLevel, '');
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getCountList: function () {
            this.apis.postForm('/system/report/getCountList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.countData = res.data || [];
                        let str = this.countData.programCount + '';
                        this.countData.programCountList = [];
                        // eslint-disable-next-line for-direction
                        for (let i = 0; i < str.length; i++) {
                            this.countData.programCountList.push(str.charAt(i));
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getRegionEnrollList: function () {
            this.apis.postForm('/system/report/getRegionEnrollList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.regionEnrollList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getRegionTeamList: function () {
            this.apis.postForm('/system/report/getRegionTeamList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.regionTeamList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getMonthEnrollList: function () {
            this.apis.postForm('/system/report/getMonthEnrollList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.monthEnrollList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getSchedRankList: function () {
            this.apis.postForm('/system/report/getSchedRankList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.schedRankList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.report-main {
    padding: 20px 30px;
    background: #F0F2F5;
    opacity: 1;
    border-radius: 0px;
    width: 100%;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        .line {
            width: 6px;
            height: 18px;
            background: #1E90FF;
            opacity: 1;
            border-radius: 8px;
        }
        .title {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 0px;
            color: #333333;
            opacity: 1;
            margin-left: 10px;
            //line-height: 38px;
        }
        .right {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            .box {
                width: 90px;
                opacity: 1;
                border-radius: 0px;
                height: 100%;
                margin: 0 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .paih {
                width: 14px;
                height: 14px;
            }
            .font {
                background: #FFFFFF;
                line-height: 32px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #1E90FF;
                opacity: 1;
                span {
                    margin-left: 4px;
                }
            }
        }
    }
    .row-common {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .card {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        padding: 0 10px;
        margin-right: 20px;
        .head {
            height: 50px;
            border-bottom: 1px solid #E5E5E5;
            .title {
                display:inline-block;
                clear:both;
                line-height: 42px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                opacity: 1;
                border-top: 4px solid #FFFFFF;
                border-bottom: 4px solid #1E90FF;
                border-radius: 2px;
                margin: 0 14px;
                box-sizing: border-box;
            }
        }
        .body {

        }
    }
    .card:last-child {
        margin-right: 0 !important;
    }
}
</style>
